import FTAnalytic from "./FTAnalytic";
import { FTImageStore } from "src/store";
import { utils } from "src/utils";

function getCommonLabel() {
  const currentEffect = FTImageStore.currentEffect;
  const effectClassName = currentEffect?.firstCategory ?? "";
  const effectEnClassName = currentEffect?.firstCategoryEn ?? "";
  const className = FTImageStore.currentClassify;
  const currentClassifyId = FTImageStore.currentClassifyId;
  const classifyId = currentEffect?.classifyId ?? "";
  return {
    resource_id: FTImageStore.currentEffect
      ? utils.toFixed(FTImageStore.currentEffect.id, 0)
      : "original",
    resource_name: FTImageStore.currentEffect
      ? FTImageStore.currentEffect?.enName
      : "original",
    category_id: classifyId || currentClassifyId || "All",
    category_name: effectEnClassName || effectClassName || className || "All",
    style_id: currentEffect?._id
  };
}

//获取页面 path，主要用于匹配页面对应的 deeplink
export function getPath(path = window.location.pathname) {
  //去掉路径后面的斜杠
  if (path !== "/" && path[path.length - 1] === "/") {
    path = path.substring(0, path.length - 1);
  }
  return path;
}
/**
 * 进入页面
 */
export function actionEnter(label = {}) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$enter$goart_project",
    label: label
  });
}
//#region 上传图片埋点
export function actionImportImageClick(position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_import_image",
    label: {
      position
    }
  });
}

export function actionGoartMonitor({
  position,
  fail_reason = "",
  time = 0
}: {
  position: string;
  fail_reason?: string;
  time?: number;
}) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$show$goart_public_monitor",
    label: {
      position,
      fail_reason,
      request_time: time
    }
  });
}

export function actionImportImageShow(position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$show$goart_import_image",
    label: {
      position
    }
  });
}

export function actionImportImageClose(position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$close$goart_import_image",
    label: {
      position
    }
  });
}

export function actionImportImageResult(position: string, isSuccess: boolean) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$callback$goart_import_image",
    label: {
      position,
      is_success: isSuccess
    }
  });
}
//#endregion
//#region 下载埋点
export function actionTryDownload() {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$try_export$goart_project",
    label: {
      ...getCommonLabel(),
      resource_id: FTImageStore.currentEffect
        ? utils.toFixed(FTImageStore.currentEffect.id, 0)
        : "original",
      resource_name: FTImageStore.currentEffect
        ? FTImageStore.currentEffect?.enName
        : "original",
      task_id: FTImageStore.currentTask?.taskId
    }
  });
}

export function acitonCollection({ is_success }: { is_success: boolean }) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_project_favorite_effects",
    label: {
      ...getCommonLabel(),
      is_success
    }
  });
}

export function actionStartDownload(position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$download$goart_project",
    label: {
      ...getCommonLabel(),
      resource_id: FTImageStore.currentEffect
        ? utils.toFixed(FTImageStore.currentEffect.id, 0)
        : "original",
      position: position,
      resource_name: FTImageStore.currentEffect
        ? FTImageStore.currentEffect?.enName
        : "original",
      task_id: FTImageStore.currentTask?.taskId
    }
  });
}

export function actionDownloadResult(
  position: string,
  isSuccess: boolean,
  failReason?: string
) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$callback$goart_project",
    label: {
      ...getCommonLabel(),
      position: position,
      is_success: isSuccess,
      resource_name: FTImageStore.currentEffect
        ? FTImageStore.currentEffect?.enName
        : "original",
      resource_id: FTImageStore.currentEffect
        ? utils.toFixed(FTImageStore.currentEffect.id, 0)
        : "original",
      fail_reason: failReason,
      task_id: FTImageStore.currentTask?.taskId
    }
  });
}
//#endregion
//#region 点击效果埋点
export function actionClickUp(
  resource_id: any,
  resource_name: any,
  label = {}
) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_project_effects",
    label: {
      ...getCommonLabel(),
      resource_id: resource_id,
      resource_name: resource_name,
      ...label
    }
  });
}

export function actionGroupClickUp(
  id: string = "All",
  name: string = "All",
  label = {}
) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_project_effects_group",
    label: {
      ...getCommonLabel(),
      category_id: id,
      category_name: name,

      ...label
    }
  });
}
//#endregion
//#region 广告埋点
export function actionAdShow(label = {}) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$show$goart_ad",
    label: label
  });
}

export function actionAdClickUp(label = {}) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_ad",
    label: label
  });
}
//#endregion

export function actionWebError(label = {}) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$web_error$common",
    label: label
  });
}

export function actionSearch(search: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$search$goart_search",
    label: {
      search: search
    }
  });
}

//#region 活动埋点

export function actionActivityShow(styleType: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$show$activity_dailog",
    label: {
      location: "goart",
      type: styleType
    }
  });
}

export function actionActivityClickUp(
  styleType: string,
  button_position: string
) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$activity_dailog",
    label: {
      location: "goart",
      type: styleType,
      button_position: button_position
    }
  });
}
//#endregion

//#region #生成埋点
export function actionStartGenerate(position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_generate",
    label: { position, ...getCommonLabel() }
  });
}

export function actionGenerateResult(
  position: string,
  isSuccess: boolean,
  reason: string
) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$callback$goart_generate",
    label: {
      position,
      ...getCommonLabel(),
      is_success: isSuccess,
      fail_reason: reason
    }
  });
}

//#endregion

//#region #移动访问
export function actionOpenVisitApp(position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$event$open_app_visit",
    label: {
      position: position,
      url: getPath(),
      type: "adjust",
      key: "adjust"
    }
  });
}

/**分享展示弹窗 */
export function actionSharePopAnlazy(position: "limit" | "share" | "invite") {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$show$goart_project_limit",
    label: {
      is_success: true,
      position
    }
  });
}
/** 点击各类弹窗按钮类型 */
export function actionClickPopTypeAnlazy(
  position:
    | "upgrade"
    | "share"
    | "close"
    | "share_discord"
    | "share_facebook"
    | "share_twitter"
    | "share_pinterest"
    | "share_linkedIn"
    | "invite_discord"
    | "invite_facebook"
    | "invite_twitter"
    | "invite_pinterest"
    | "invite_linkedIn"
    | "copy"
    | "earn"
) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_project_limit",
    label: {
      is_success: true,
      position
    }
  });
}

export function actionShowLimitPopAnlazy(type: number) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$show$goart_project_429",
    label: {
      is_success: true,
      type
    }
  });
}
//弹窗展示
export function enterPopupShow(position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$show$drownload_app_popup",
    label: {
      position: position,
      url: getPath(),
      type: "adjust",
      key: "adjust"
    }
  });
}

export function actionClickLimitPopAnlazy(
  position: "upgrade" | "close",
  type: number
) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_project_429",
    label: {
      is_success: true,
      position,
      type
    }
  });
}
//弹窗展示
export function enterPopupClick(position: string, button_position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$drownload_app_popup",
    label: {
      position: position,
      button_position: button_position,
      url: getPath(),
      type: "adjust",
      key: "adjust"
    }
  });
}

//#endregion
export function actionFeatureClickUp(position: string) {
  FTAnalytic.addAnalytic({
    type: "ev",
    analyticType: "sa",
    action: "fotor_web$click_up$goart_page",
    label: {
      button_position: position
    }
  });
}
